<template>
    <div class="replaceCon" v-loading="loading">
        <div class="introCon" v-if="data">
            <div class="introConLeft">
                <img :src="data?.hdCover?.path">
            </div>
            <div class="introConRight">
                <div class="tntroBlock">
                    <label class="nameIcon"></label>
                    <span class="introNameTitle">主持人</span>
                    <span class="introName">{{data.title}}</span>
                </div>
                <div class="tntroBlock" style="margin-top:150px;">
                    <label class="infoIcon"></label>
                    <span class="introNameTitle">个人信息</span>
                    <br>
                    <br>
                    <br>
                    <div class="introText" v-html="data.content">
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { APPID } from '@/utils/api/config'
export default {
    data() {
        return {
            id: undefined,
            data:null,
            loading:false
        }
    },
    created() {
        this.id = this.$route.params.id
        console.log(this.$route.params.id)
        this.loading = true
        this.getInfo()
    },
    methods: {
        getInfo() {
            this.axios.get(`api/fusion/pc/getNewsInfo?appId=${APPID}&newsId=${this.id}`).then(res => {
                this.data = res.data
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.replaceCon {
    padding-bottom: 120px;
}

.introBox {
    width: 1200px;
    margin: 0 auto;
}

.introCon {
    width: 100%;
    min-height: 725px;
    margin-top: 35px;
    margin-bottom: 80px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start
}

.introConLeft {
    width: 514px;
    min-height: 725px;
    margin-right: 100px;
}

.introConLeft img {
    width: 100%;
}

.introConRight {
    width: calc(100% - 614px);
}

.tntroBlock {
    margin-bottom: 45px;
}

.nameIcon {
    display: inline-block;
    width: 45px;
    height: 50px;
    background-image: url(../../assets/img/hoster/introicon1.png);
    background-size: 100% 100%;
    vertical-align: bottom;
}

.infoIcon {
    display: inline-block;
    width: 45px;
    height: 45px;
    background-image: url(../../assets/img/hoster/introicon2.png);
    background-size: 100% 100%;
    vertical-align: bottom;
}

.introNameTitle {
    font-size: 24px;
    color: black;
    margin-left: 10px;
    margin-right: 15px;
}

.introName {
    font-size: 30px;
    font-weight: bold;
    color: black;
}

.introText {
    font-size: 16px;
    color: #666;
    line-height: 1.7;
}

.introText p {
    margin: 0;
}

.blockUnit {
    width: min-content;
    height: 46px;
    border-radius: 35px;
    background: #f4e5e5;
    color: #7b2525;
    font-size: 22px;
    text-align: center;
    line-height: 46px;
    padding: 0 25px;
}</style>